// src/pages/Apply.js
import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
// import "bootstrap-icons/font/bootstrap-icons.css"; // Bootstrap Icons
// import "./app.css"; // Your custom CSS


const Apply = () => {
  return (
    <div className="app">
      {/* Navbar */}
      <nav id="navbar" className="navbar navbar-expand-md navbar-light">
        {/* You can include your Navbar component here if you have one */}
      </nav>

      {/* Header */}
      <div className="container-fluid mt-5">
        <div className="container light-bg">
          <div className="row">
            <div className="col-10">
              <h1 className="cl-blue-900 mt-3">Testing Engineer</h1>
              <div className="d-flex flex-column flex-sm-row justify-content-between mt-5">
                <div className="me-3">
                  <div className="h5">Job Description</div>
                  <p className="w-75">
                    Remote, India, 4 to 5 Years Of Experience Department:
                    Website Design 5 Positions Available.
                  </p>
                </div>
                <div>
                  <div className="h5">Salary</div>
                  <p>$30000 Per Annum</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Detail */}
      <div className="container-fluid mt-5">
        <div className="container bg-bluefaint light-bg b-6 p-3 p-md-5">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-4 h5 cl-primary">Details</div>
                <div className="col-12 col-sm-8">
                  <p>
                    Design & Create highly engaging industry-related content in
                    both photo, gif & video format. Publish Posts on various
                    social media channels.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-4 h5">Requirements</div>
                <div className="col-12 col-sm-8">
                  <p>
                    Promote content on social networks and monitor engagement
                    (e.g. comments and shares). Research industry-related
                    topics.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-4 h5">Responsibilities</div>
                <div className="col-12 col-sm-8">
                  <ul className="p-sm-0">
                    <li>Editing audio and sound design on projects</li>
                    <li>
                      Engage in opportunities to develop original content and
                      concepts for web and mobile
                    </li>
                    <li>
                      Create motion graphics and animations using 2D and 3D
                      applications for marketing and promotional usage.
                    </li>
                    <li>
                      Manage the day-to-day handling of all social media
                      channels such as LinkedIn, Facebook, Twitter, Pinterest,
                      Instagram, TikTok, and YouTube, adapting content to suit
                      different channels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Apply section */}
      <div className="container-fluid py-5 my-4">
        <div className="container">
          <h1>Apply Now</h1>
          <div className="mt-5">
            <form className="row g-3" action="">
              <div className="col-12">
                <div className="row row-cols-2 g-3">
                  <div className="form-floating col-12 col-md-6">
                    <input
                      className="form-control border-0 bg-blue-200 px-3"
                      type="text"
                      placeholder="First Name"
                      id="floatingFirstName"
                    />
                    <label className="ms-3" htmlFor="floatingFirstName">
                      First Name
                    </label>
                  </div>
                  <div className="form-floating col-12 col-md-6">
                    <input
                      className="form-control border-0 bg-blue-200 px-3"
                      type="text"
                      placeholder="Last Name"
                      id="floatingLastName"
                    />
                    <label className="ms-3" htmlFor="floatingLastName">
                      Last Name
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row row-cols-2 g-3">
                  <div className="form-floating col-12 col-md-6">
                    <input
                      className="form-control border-0 bg-blue-200 px-3"
                      type="email"
                      placeholder="Email Address"
                      id="floatingEmail"
                    />
                    <label className="ms-3" htmlFor="floatingEmail">
                      Email Address
                    </label>
                  </div>
                  <div className="form-floating col-12 col-md-6">
                    <input
                      className="form-control border-0 bg-blue-200 px-3"
                      type="text"
                      placeholder="Mobile Number"
                      id="floatingMobile"
                    />
                    <label className="ms-3" htmlFor="floatingMobile">
                      Mobile Number
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-floating col-12">
                <textarea
                  className="form-control border-0 bg-blue-200 b-6 px-3"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "140px", resize: "none" }}
                ></textarea>
                <label className="ms-3" htmlFor="floatingTextarea2">
                  Why do you think you are a good fit for Ether?
                </label>
              </div>
              <div className="col-12 pt-3">
                <button
                  id="applyFormSubmitBtn"
                  type="submit"
                  className="btn btn-primary bg-brand-primary px-5 py-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply;
