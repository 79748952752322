// src/components/VideoSection.js
import React from "react";
import videoImg from "../images/Videosection-Img.png";
import personImg from "../images/person-img.png";

const VideoSection = () => (
  <div className="container light-bg">
    <div className="row align-items-center">
      <div className="col-12 col-md-6 py-5">
        <h2>Finsweet was a dream to work with</h2>
        <p className="my-4">
          Maecenas efficitur scelerisque lorem, et varius lacus tincidunt vel.
          Pellentesque a arcu vitae diam dapibus mattis vel vel orci. Vivamus
          eleifend nec felis vel auctor.
        </p>
        <figcaption className="reviewer-info d-flex align-items-center mt-5">
          <img
            className="rounded-circle"
            src={personImg}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
          <div className="px-3">
            <div className="fs-6 cl-blue-700 fw-bold">Chikelu Neo</div>
            <span className="mt-1 cl-primary">CEO at MazeAI</span>
          </div>
        </figcaption>
      </div>
      <div className="col-12 col-md-6 video-wrapper overflow-hidden b-6 position-relative">
        <img src={videoImg} className="img-fluid" alt="Video Section" />
        <a
          className="video-play-btn b-6 bg-white shadow position-absolute top-50 start-50 translate-middle"
          href="#home"
        >
          <i className="bi bi-play-fill cl-primary-900 fs-2"></i>
        </a>
      </div>
    </div>
  </div>
);

export default VideoSection;
