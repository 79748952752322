import React from "react";
import headerImgServ from "../images/headerImg-Serv.png";
import serviceFeatureImg1 from "../images/service-featureImg1.png";
import serviceFeatureImg2 from "../images/service-featureImg2.png";
import serviceFeatureImg3 from "../images/service-featureImg3.png";
import serviceFeatureImg4 from "../images/service-featureImg4.png";
import serviceFeatureImg5 from "../images/service-featureImg5.png";

const Service = () => {
  return (
    <>
      <div className="container-fluid py-4 py-md-5 light-bg overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5 col-lg-6 py-3">
              <div className="pt-lg-5">
                <h1 className="cl-blue-900 mt-3">
                  We serve clients with groundbreaking solutions
                </h1>
                <p className="my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique
                </p>
              </div>
              <div className="mt-4">
                <a
                  href="/apply"
                  type="button"
                  className="btn btn-primary my-2 bg-brand-primay"
                >
                  Work With Us
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-6 position-relative">
              <div className="img-bar"></div>
              <img
                src="./images/Circle.svg"
                alt=""
                className="overlay-img--circle p-ru position-absolute"
              />
              <img className="img-fit-cover b-6" src={headerImgServ} alt="" />
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid light-bg overflow-hidden mt-5 mt-md-4">
        <div className="container">
          <div className="row gy-5 gy-md-0">
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative order-md-1 order-2">
                  <div className="position-relative">
                    <div className="overlay-img--cubeSm p-lu position-absolute b-6"></div>
                    <img
                      className="img-fit-cover b-6"
                      src={serviceFeatureImg1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1">
                  <h2>Business strategy</h2>
                  <p className="py-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam leo odio, sagittis quis ornare quis. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Aliquam leo
                    odio, sagittis quis ornare quis.
                  </p>
                  <div>
                    <ul className="px-3">
                      <li>Cost strategy</li>
                      <li>Differentiated product or service strategy</li>
                      <li>Focus on a niche strategy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6">
                  <h2>Digitalization</h2>
                  <p className="py-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam leo odio, sagittis quis ornare quis. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Aliquam leo
                    odio, sagittis quis ornare quis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Aliquam leo odio, sagittis.
                  </p>
                </div>
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative">
                  <div className="position-relative">
                    <div className="overlay-img--cubeSm p-rc position-absolute b-6"></div>
                    <img
                      className="img-fit-cover b-6"
                      src={serviceFeatureImg2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative order-md-1 order-2">
                  <div className="position-relative">
                    <div className="overlay-img--cubeSm p-lc position-absolute b-6"></div>
                    <img
                      className="img-fit-cover b-6"
                      src={serviceFeatureImg3}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1">
                  <h2>Risk assessment</h2>
                  <p className="py-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam leo odio, sagittis quis ornare quis. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Aliquam leo
                    odio, sagittis quis ornare quis.
                  </p>
                  <div>
                    <ul className="px-3">
                      <li>Individual risk assessment</li>
                      <li>Systems risk assessment</li>
                      <li>Mathematical conceptualization</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6">
                  <h2>Artificial intelligence</h2>
                  <p className="py-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam leo odio, sagittis quis ornare quis. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Aliquam leo
                    odio, sagittis quis ornare quis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Aliquam leo odio, sagittis.
                  </p>
                </div>
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative">
                  <div className="position-relative">
                    <div className="overlay-img--cubeSm p-rc position-absolute b-6"></div>
                    <img
                      className="img-fit-cover b-6"
                      src={serviceFeatureImg4}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative order-md-1 order-2">
                  <div className="position-relative">
                    <div className="overlay-img--cubeSm p-ld position-absolute b-6"></div>
                    <img
                      className="img-fit-cover b-6"
                      src={serviceFeatureImg5}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1">
                  <h2>Smart Contracts</h2>
                  <p className="py-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam leo odio, sagittis quis ornare quis. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Aliquam leo
                    odio, sagittis quis ornare quis.
                  </p>
                  <div>
                    <ul className="px-3">
                      <li>Smart contract implementation</li>
                      <li>Smart legal contracts</li>
                      <li>Basic contract law</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
