import React from "react";
import HeaderLogo from "../images/Header-Logos.png";
import HeaderVid2 from "../images/miden.mp4";
// import HerosectionImg from "../images/Herosection-Img.png";
// import wandmagicsparkel from "../images/wand-magic-sparkel.svg";
// import layergroup from "../images/layer-group.svg";
// import heartsvg from "../images/heart.svg";
import circlesvg from "../images/Circle.svg";
import aboutSectionImg from "../images/Servicesection-Img.png";
import serviceSectionImg from "../images/service-featureImg5.png";
import ctaImg from "../images/CTA-Img.png";
import Posts from "../components/Posts";

import design from "../images/Design.png";
import webdev from "../images/Web development.png";
import custsoft from "../images/Custom software.png";

const Home = () => {
  return (
    <>
      {/* Header */}
      <div className="bg-blueDark container-fluid pt-5 light-bg">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-5 col-lg-6 py-3">
              <div>
                <h1 className="cl-white">
                  Empower Your Vision with Uncube Labs
                </h1>
                <p className="cl-white">
                  Uncube Labs transforms your ideas into innovative digital
                  solutions.
                </p>
              </div>
              <div className="mt-4">
                <a
                  href="#services"
                  type="button"
                  className="btn btn my-2 bg-brand-primay"
                >
                  See our services
                </a>
                <a
                  href="./service"
                  className="btn cl-white border border-white fw-smbold ms-sm-3 ms-md-0 ms-lg-3"
                >
                  See our products
                </a>
              </div>
              <div className="mt-5">
                <span className="cl-white">Worked with 100+ Companies</span>
                <div className="mt-3">
                  <marquee>
                    <img className="w-75" src={HeaderLogo} alt="headerLogos" />
                  </marquee>
                </div>
              </div>
            </div>
            <div className="header-img-wrapper col-md-7 col-lg-6 overflow-hidden">
              {/* <img
                className="img-fit-cover mt-5"
                src={HerosectionImg}
                alt="heroSectionImg"
              /> */}

              <video
                src={HeaderVid2}
                type="video/mp4"
                autoPlay
                loop
                muted
                className="img-fit-cover mt-5"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Help Section */}
      <section
        data-posts-category="helpPosts"
        id="helpSection"
        className="container-fluid py-5 mt-3 light-bg"
      >
        {" "}
        <Posts />
      </section>

      {/* Features Section */}
      <section className="container-fluid py-5 light-bg">
        <div className="container">
          <div className="row gy-3 gy-md-5">
            <div className="text-container col-12">
              <div className="text-wrapper row row-cols-2 gy-3">
                <h3 className="col col-12 col-md-6">
                  At Uncube Labs, we specialize in crafting innovative solutions
                </h3>
                <p className="col col-12 col-md-6">
                  Our team blends creativity with technical expertise to deliver
                  software that drives your business forward. Let us collaborate
                  to bring your ideas to life and exceed your expectations.
                </p>
              </div>
            </div>
            <div className="features col-12">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gy-3 gy-md-0">
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={design} type="image/svg" alt="" />
                      <h5 className="card-title cl-black my-3">Design</h5>
                      <p className="card-text">
                        We are user experience and UI design providers
                        specialised in designing websites, mobile apps, or
                        multi-platform solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={webdev} type="image/svg" alt="" />
                      <h5 className="card-title cl-blue-900 my-3">
                        Web Development
                      </h5>
                      <p className="card-text">
                        Best in building exceptional websites with the
                        cutting-edge technologies at both frontend and backend
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={custsoft} type="image/svg" alt="" />
                      <h5 className="card-title cl-blue-900 my-3">
                        Custom software development
                      </h5>
                      <p className="card-text">
                        We plan, design, develop, integrate, manage and evolve
                        the software solutions while following the agile
                        methodology
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="features col-12">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 gy-3 gy-md-0">
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={custsoft} type="image/svg" alt="" />
                      <h5 className="card-title cl-blue-900 my-3">
                        App development
                      </h5>
                      <p className="card-text">
                        We build native, web, hybrid and even multiplatform apps
                        that provide excellent functionality without
                        compromising user experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={custsoft} type="image/svg" alt="" />
                      <h5 className="card-title cl-blue-900 my-3">
                        Web3 / Blockchain
                      </h5>
                      <p className="card-text">
                        We customise smart contract, dApp, tokenization, secure
                        wallet integration, and blockchain advice. We tailor
                        solutions leveraging Web3 ecosystem expertise.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0 b-6">
                    <div className="card-body px-4 py-5">
                      <img src={custsoft} type="image/svg" alt="" />
                      <h5 className="card-title cl-blue-900 my-3">AI / ML</h5>
                      <p className="card-text">
                        We provide AI model creation, ML algorithm
                        implementation, data analysis, predictive analytics,
                        NLP, and AI consultation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ---------row two--------------------------- */}
        </div>
      </section>

      {/* About Section */}
      <section className="container-fluid py-5 mt-4 overflow-hidden light-bg">
        <div className="container position-relative">
          <div className="row row-cols-2">
            <div className="col-12 col-md-6 py-4 py-md-5">
              <h2>
                The energy of a start-up combined with 5 years of experience.
              </h2>
              <p className="mt-3">
                At Uncube Labs, we blend the dynamic energy of a startup with
                the seasoned proficiency that comes from five years of dedicated
                experience. Our journey has empowered us with the agility to
                swiftly adapt and innovate, while our established track record
                ensures reliability and deep industry insights. We harness this
                unique combination to deliver transformative solutions that
                drive your business forward..
              </p>
              <div className="row row-cols-2 mt-5">
                <div className="col-12 col-sm-6">
                  <span className="h2 cl-primary"> 5+ </span>
                  <h5>Awards received</h5>
                  <p>
                    Received 5+ Awards including recognisition from SPPU's
                    Research Park Foundation
                  </p>
                </div>
                <div className="col-12 col-sm-6">
                  <span className="h2 cl-primary"> 100+ </span>
                  <h5>Clients served</h5>
                  <p>
                    Uncube's journey started back in 2021 under Saphite
                    Technologies Pvt. Ltd. Since then uncube is serving clients,
                    in 2024 it became independant entity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 position-relative">
              {/* <div className="img-bar"></div> */}
              <img
                src={circlesvg}
                alt=""
                className="overlay-img--circle p-tr position-absolute"
              />
              <img className="img-fit-cover" src={aboutSectionImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="container-fluid py-5 overflow-hidden">
        <div className="container b-6 p-4 position-relative">
          <div className="row">
            <div className="serviceTabs-wrapper col-12">
              <button type="button" className="btn cl-black m-2 fs-7" disabled>
                Design
              </button>
              <button
                type="button"
                className="btn cl-black btn-outline-secondary  m-2 fs-7"
                disabled
              >
                Development
              </button>
              <button
                type="button"
                className="btn cl-black btn-outline-secondary  m-2 fs-7"
                disabled
              >
                Remote Resource
              </button>
            </div>
            <div className="service-section-wrappper col-12">
              <div className="row pt-4 pt-md-5 gx-5 row-cols-2">
                <div className="col-12 col-md-6 py-4 py-md-5 order-2 order-md-1">
                  <h2>
                    Empowering Businesses Globally with Strategic Research,
                    Design, and Development
                  </h2>
                  <div className="p-body mt-3 mt-sm-4 mt-md-5">
                    <p>
                      At Uncube Labs, we partner with businesses worldwide to
                      fuel their growth through insightful research and
                      strategic planning. Our India-based team combines
                      cutting-edge design and expert software development to
                      create customized solutions that meet the unique needs of
                      our clients. From initial concept to final execution, we
                      ensure that your vision is realized and your objectives
                      are achieved.
                    </p>
                    <p>
                      Mattis purus. Vivamus commodo suscipit tellus et pellent.
                      Curabitur sit amet eros blan esque.
                    </p>
                  </div>
                  <div className="mt-3 mt-sm-4 mt-md-5">
                    <a href="./service">
                      See all services <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6 overflow-hidden b-6 position-relative order-1 order-md-2">
                  <img
                    className="img-fit-cover"
                    src={serviceSectionImg}
                    alt=""
                  />
                  {/* <div className="img-bar"></div> */}
                </div>
              </div>
            </div>
          </div>
          <img
            className="overlay-img--ribbon position-absolute"
            src="./images/Ribbon.svg"
            alt=""
            type="image/svg"
          />
        </div>
      </section>

      {/* CTA */}
      <div className="container-fluid p-0 overflow-hidden bg-black align-items-center">
        <div className="row g-0 flex-row-reverse align-items-center justify-content-end">
          <div className="col-12 col-lg-6 overflow-hidden">
            <div className="cta-img-wrapper h-100 position-relative b-6">
              <img
                className="img-fit-cover w-100 h-100"
                src={ctaImg}
                alt="ctaImg"
              />
            </div>
          </div>
          <div className="cta-wrapper col-12 col-lg-6 py-5">
            <div className="container px-5 py-lg-5">
              <h2 className="text-white">
                Let's start working together today!
              </h2>
              <p className="text-white mt-4 mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique.
              </p>
              <a href="/service" className="btn bg-light text-dark btn-lg fs-7">
                Start here
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer id="footer" className="dark-bg"></footer>
    </>
  );
};

export default Home;
