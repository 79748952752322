import React from "react";
import Posts from "../components/Posts";
import VideoSection from "../components/VideoSection";
import circleSvg from "../images/Circle.svg";
import headerImgAboutUs from "../images/Aboutsection-Img.png";
import aboutSec from "../images/AboutUs-AbtSec.png";
import aboutUsTeamPost1 from "../images/blogSection-img2.png";

const AboutUs = () => (
  <>
    <div className="app">
      {/* Header */}
      <div className="container-fluid py-4 py-md-5 light-bg overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5 col-lg-6 py-3">
              <div>
                <h1 className="cl-blue-900 mt-3">
                  We value human, organizational, and operational intelligence,
                  not just artificial
                </h1>
                <p className="my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique
                </p>
              </div>
              <div className="mt-4">
                <a
                  href="/apply"
                  type="button"
                  className="btn btn-primary my-2 bg-brand-primay"
                >
                  Work With Us
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-6 position-relative">
              <div className="img-bar"></div>
              <img
                src={circleSvg}
                alt=""
                className="overlay-img--circle p-br position-absolute"
              />
              <img
                className="img-fit-cover"
                src={headerImgAboutUs}
                alt=""
                srcSet=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* Feature section */}
      <section className="container-fluid my-5 py-5 light-bg">
        <div className="container">
          <div className="row gy-3 gy-md-5">
            <div className="text-container col-12">
              <div className="text-wrapper row row-cols-2 gy-3">
                <h3 className="col col-12 col-md-6">
                  The energy of a start-up combined with 30 years of experience
                </h3>
                <p className="col col-12 col-md-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur sit amet eros blandit, hendrerit elit et, mattis
                  purus. Vivamus commodo suscipit tellus et pellentesque.
                </p>
              </div>
            </div>
            <div className="features col-12">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 gy-3 gy-md-0">
                <div className="col">
                  <span className="h2 cl-primary">15+</span>
                  <h5>Awards received</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sit amet eros blandit, hendrerit elit et.
                  </p>
                </div>
                <div className="col">
                  <span className="h2 cl-primary">500+</span>
                  <h5>Clients served</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sit amet eros blandit, hendrerit elit et.
                  </p>
                </div>
                <div className="col">
                  <span className="h2 cl-primary">34</span>
                  <h5>Employees</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sit amet eros blandit, hendrerit elit et.
                  </p>
                </div>
                <div className="col">
                  <span className="h2 cl-primary">130+</span>
                  <h5>Custom solutions</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur sit amet eros blandit, hendrerit elit et.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About section */}
      <section className="container-fluid light-bg pb-5 overflow-hidden">
        <div className="container">
          <div className="row row-cols-2 gx-5 align-items-center">
            <div className="col-12 col-md-6">
              <h2>
                We want to get local identification in every corner of the world
                in this era of global citizenship.
              </h2>
              <p className="py-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sit amet eros blandit, hendrerit elit et, mattis
                purus. Vivamus commodo suscipit tellus et pellentesque.
              </p>
            </div>
            <div className="col-12 col-md-6 img-wrapper position-relative p-0">
              <div className="p-4">
                <div className="overlay-img--cubeLg position-absolute b-6"></div>
                <img className="img-fit-cover" src={aboutSec} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team section */}
      <div className="container-fluid light-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Teamwork is the only way we work</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sit amet eros blandit, hendrerit elit et, mattis
                purus. Vivamus commodo suscipit tellus et pellentesque.
              </p>
            </div>
            <div className="col-12">
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fit-cover"
                      src={aboutUsTeamPost1}
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Video Section */}
      <section
        id="videoSection"
        className="container-fluid py-5 my-md-3 my-lg-5"
      >
        <VideoSection />
      </section>

      {/* Latest Section */}
      <section
        data-posts-category="latestBlogAndNews"
        className="container-fluid py-5 bg-bluelight light-bg"
      >
        <Posts />
      </section>
    </div>
  </>
);

export default AboutUs;
