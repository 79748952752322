import React from "react";

const BlogInner = () => {
  return (
    <div className="app">
      {/* Navbar */}
      <nav id="navbar" className="navbar navbar-expand-md navbar-light"></nav>

      {/* Header */}
      <div className="container-fluid py-4 py-md-5 bg-bluelight light-bg">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-5 col-lg-6 py-3">
              <div className="pt-lg-5">
                <figcaption className="reviewer-info d-flex align-items-center">
                  <img
                    className="rounded-circle"
                    src="./images/person-img.png"
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div className="d-flex flex-wrap">
                    <span className="px-2 fs-7">Andrew Jonson</span>
                    <span className="px-2 fs-7">
                      Posted on 27th January 2021
                    </span>
                  </div>
                </figcaption>
                <h1 className="cl-blue-900 mt-3">
                  Our internal process and long-term vision
                </h1>
                <p className="my-4">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The Maker is a decentralized. We aim to attain the
                </p>
                <a href="./BlogInner.html" className="my-2">
                  Read More <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-6 position-relative">
              <img
                className="img-fit-cover b-6"
                src="./images/headerImg-Blog.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* Latest Section */}
      <section
        data-posts-category="helpPosts"
        className="container-fluid py-5 light-bg"
      ></section>

      {/* Footer */}
      <footer
        id="footer"
        className="container-fluid bg-blueDark py-5 dark-bg"
      ></footer>
    </div>
  );
};

export default BlogInner;
