import React, { useEffect, useState } from "react";
// import service1 from "../images/helpSection-img1.jpg";
// import service2 from "../images/helpSection-img2.jpg";
// import service3 from "../images/helpSection-img3.jpg";
import project1 from "../images/Peak_Consulting_Portfolio.webp";
import project2 from "../images/Portfolio-EonsTechnologies.webp";
import project3 from "../images/Rnergi_Portfolio.webp";

const helpPosts = [
  {
    name: "helpPosts",
    title:
      "We collaborate with aspirational global organizations, transforming their visions into  real-world impact.",
    description: "Some of our projects.",
    PostsObject: [
      {
        title: "Peak Consulting",
        description:
          "Singapore based ed-tech, specialized in Mathematics & Physics Teaching.",
        imgURL: project1,
        link: "https://peakconsulting.com.sg/",
      },
      {
        title: "EONS Technologies",
        description: "Renewable solutions and Urban Development Company",
        imgURL: project2,
        link: "https://www.eons-tech.com/",
      },
      {
        title: "Rnergi Future Energies",
        description:
          "Global Sustainable Innovations Company working towards sustainable growth.",
        imgURL: project3,
        link: "https://rnergi.com/",
      },
    ],
  },
];

const Posts = ({ category }) => {
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    // Set the helpPosts data directly when the component mounts
    setPostData(helpPosts);
  }, []);

  return (
    <section className="container-fluid py-5 light-bg">
      {postData.map((categoryData) => (
        <div key={categoryData.name} className="container">
          <h2 className="cl-blue-900">{categoryData.title}</h2>
          <p className="mt-3">{categoryData.description}</p>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mt-3">
            {categoryData.PostsObject.map((post, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 mb-4">
                {" "}
                {/* Update classes here */}
                <div className="card h-100 border-0 bg-transparent">
                  <img
                    src={post.imgURL} // Ensure the src directly uses the image URL
                    className="card-img-top"
                    alt={post.title}
                  />
                  <div className="card-body px-0 py-4">
                    <h5 className="card-title cl-primary-800">{post.title}</h5>
                    <p className="card-text cl-battelshipGrey-600">
                      {post.description}
                    </p>
                    <a
                      href={post.link}
                      target="_blank"
                      rel="noreferrer"
                      className=""
                    >
                      View Details <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Posts;
