import React from "react";
import UncubeHoriLogo from "../images/Uncube-white.png";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-md bg-brandDark">
        <div className="container">
          <a className="navbar-brand brand-logo" href="/">
            <img className="img-fluid" src={UncubeHoriLogo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link cl-white" href="/aboutus">
                  About Us
                </a>
              </li>
              <li className="nav-item ms-md-1">
                <a className="nav-link cl-white" href="/careers">
                  Careers
                </a>
              </li>
              <li className="nav-item ms-md-1">
                <a className="nav-link cl-white" href="/service">
                  Services
                </a>
              </li>
              <li className="nav-item ms-md-1">
                <a className="nav-link cl-white" href="/blog">
                  Blog
                </a>
              </li>
              <li className="nav-item mx-md-1">
                <a className="nav-link cl-white" href="/contactus">
                  Contact us
                </a>
              </li>
            </ul>
            <a
              target="_"
              href="https://cal.com/uncubelabs"
              className="btn bg-brand-primay"
            >
              Schedule Meeting
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
