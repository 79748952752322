import React from "react";

const ContactUs = () => {
  return (
    <>
      {/* Header */}
      <div className="container-fluid py-5 light-bg overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h1 className="cl-blue-900">
                    Have a question? Let’s get in touch with us.
                  </h1>
                  <p>
                    Fill up the Form and our team will get back to you within 24
                    hrs
                  </p>
                </div>
                <div className="col-6 col-md-3 py-4">
                  <div className="h5">Location</div>
                  <ul className="list-unstyled">
                    <li>
                      Wework, Eleven West, Baner, Pune, Maharashtra 411045
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-3 py-4">
                  <div className="h5">Contact Us</div>
                  <ul className="list-unstyled">
                    <li>+91 8799831091</li>
                    <li>hr@uncubelabs.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact section */}
      <div className="container-fluid">
        <div className="container">
          <div className="row g-5">
            <div className="col-12 col-md-6">
              <form className="row g-3" action="">
                <div className="form-floating col-12">
                  <input
                    className="form-control border-0 bg-blue-200 px-3"
                    type="text"
                    placeholder="First Name"
                  />
                  <label className="ms-3" htmlFor="floatingInput">
                    First Name
                  </label>
                </div>
                <div className="form-floating col-12">
                  <input
                    className="form-control border-0 bg-blue-200 px-3"
                    type="text"
                    placeholder="Last Name"
                  />
                  <label className="ms-3" htmlFor="floatingInput">
                    Last Name
                  </label>
                </div>
                <div className="form-floating col-12">
                  <input
                    className="form-control border-0 bg-blue-200 px-3"
                    type="email"
                    placeholder="Email Id"
                  />
                  <label className="ms-3" htmlFor="floatingInput">
                    Email Id
                  </label>
                </div>
                <div className="form-floating col-12">
                  <input
                    className="form-control border-0 bg-blue-200 px-3"
                    type="number"
                    placeholder="Mobile Number"
                  />
                  <label className="ms-3" htmlFor="floatingInput">
                    Mobile Number
                  </label>
                </div>
                <div className="form-floating col-12">
                  <textarea
                    className="form-control border-0 bg-blue-200 b-6 px-3"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "140px", resize: "none" }}
                  ></textarea>
                  <label className="ms-3" htmlFor="floatingInput">
                    Type message
                  </label>
                </div>
                <div className="col-12 pt-3">
                  <button
                    id="applyFormSubmitBtn"
                    type="submit"
                    className="btn btn-primary bg-brand-primay px-5 py-2 mb-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-6 overflow-hidden b-6">
              <iframe
                title="uncubelabs address"
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d30254.83855870062!2d73.76695836872479!3d18.5805828815522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bc2bf24bddf0519%3A0x5ed3181ae2566460!2suncube%20labs!3m2!1d18.5565961!2d73.7831137!5e0!3m2!1sen!2sin!4v1719408070513!5m2!1sen!2sin"
                width="600"
                height="450"
                // style="border:0;"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
